import {h} from 'preact'
import {useContext} from "preact/hooks";
import {HomeContext} from "../../context/HomeContext";
import SalonCard from "../blocks/SalonCard";
import {route} from "preact-router";

export default ({count, additionalClass = ''}) => {
    const {popularStylists, locations, ...context} = useContext(HomeContext);
    let city;
    if (context.isGhana) {
        city = 'Accra'
    } else if(context.isKenya) {
        city = 'Nairobi'
    } else if(context.isSouthAfrica) {
        city = 'Johannesburg'
    } else {
        city = 'Lagos'
    }
    const openSalon = (id) => {
        route(`/stylists/${id}`)
    }
    return (
        <section className={`top-stylists ${additionalClass}`}>
            <div className="heading">
                <h2>Top <mark>Mirron</mark> stylists<br/>
                    in <mark className="underlined" style={{backgroundSize: city.length > 7 ? '50% auto' : '100% auto'}}>{city}</mark></h2>
            </div>
            <div className="result-list">
                {popularStylists.slice(0, count).map(el => <SalonCard {...el} onClickExplore={openSalon} />)}

            </div>
        </section>
    )
}